<template>
    <div style="width: 100%;height: auto;font-size: 14px;">
        <div style="width: 100%;height: 40px;display: flex;">
            <div style="width: 35px; height: 35px;">
                <img :src="repInfo.user.headIco" style="width: 35px;height: 35px;border-radius: 50%;">
            </div>
            <div style="width:calc(100% - 85px); line-height: 35px;padding-left: 10px;">{{repInfo.user.nickName}}</div>
        </div>
        <div style="width: 100%;height: auto;display: flex;">
            <div v-html="repInfo.content" style="width: calc(100% - 40px);line-height: 20px;"></div>
        </div>
        <div v-if="repInfo.images.length > 0" style="width: 100%;height: auto;display: flex; flex-wrap: wrap;">
            <div  v-for="(i,idx) in repInfo.images" :key="idx">
                <div style="width: 230px;height: 230px;margin-right: 10px; margin-bottom: 10px;">
                    <el-image  style="width: 100%; height: 100%;" :src="i['url']" :preview-src-list="[i['url']]"></el-image>
<!--                    <img style="width: 100%;height: 100%;" :src="repInfo.images[0].url">-->
                </div>
            </div>
        </div>

        <div style="width: 100%;line-height: 35px;cursor: pointer;">
            <span >回复</span> · <span style="color: #c1c1c1;">{{repInfo.user.nickName}}</span>
        </div>
        <div  style="width: 100%;height: auto;background: #F8F8F8;margin-bottom: 15px;">
            <div style="width: 100%;height: 100px;">
                <el-input type="textarea" :rows="4" placeholder="请输入内容"  v-model="textarea"></el-input>
            </div>
            <div style="width: 100%;height: 40px;display: flex;">
                <div style="width: 30px;height: 40px;">
                    <el-upload
                        class="upload-demo"
                        action=""
                        accept="image/*"
                        :http-request="upload_img" :limit=1 :show-file-list="false" :on-exceed="fileListNum">
                        <el-button size="small" type="primary" style="background: #FFFFFF;border: none;">
                            <el-badge :value="images.length > 0 ? images.length : ''" class="item">
                                <div style="width: 30px;height: 40px;">
                                    <img  src="@/assets/imgs/other/addImage.png" style="width: 30px;height: 30px;cursor: pointer;">
                                </div>
                            </el-badge>
                        </el-button>
                    </el-upload>
                </div>
            </div>
        </div>
        <div v-if="images.length > 0" style="width: 220px;height: 220px;margin-top: 10px;float: left;position: relative;">
            <div style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px;float:right; cursor:pointer;user-select: none;position: absolute;top:1px;right: 1px;" @click="removeImg()">
                <i class="el-icon-close"></i>
            </div>
            <img  :src="images[0].url" style="width: 100%;height: 100%;cursor: pointer;">
        </div>
        <div style="width: 100%;height: 40px;display: flex;">
            <div style="width: calc(100% - 65px);height: 40px;"></div>
            <el-button @click="answer" type="primary">立即发布</el-button>
        </div>
    </div>
</template>

<script>
export default{
    props: {
        repInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            repBtBgColor:false,
            textarea:"",
            images:[],
        }
    },
    mounted() {
    },
    methods: {
        // 上传图片
        upload_img(params) {
            if (this.images.length > 1) {
                this.$message.error('只能能上传一张图片！');
                return false;
            }
            var that = this;
            var file = params.file;
            if (file.type.indexOf('image') > -1) {
                that.utils.upload(file,function(url){
                    if (!url){
                        return false;
                    }
                    let params = {};
                    params.name = url.name;
                    params.url = url.url;
                    params.hash = url.hash;
                    that.images = [];
                    that.images.push(params);
                })
            }else{
                this.$message.error('只能上传图片！')
                return false
            }
        },

        //删除图片
        removeImg() {
            // 在这里处理删除逻辑
            this.images = [];
        },

        //文件超出个数限制时的钩子
        fileListNum:function (files) {
            if (files.length>this.limit) {
                this.$message.error('图片数量不能超过1张!');
            }
        },

        // 添加二级评论
        answer() {
            var that = this;
            var params = {};
            params.type = that.repInfo.type;
            params.content = that.textarea;
            params.fid = that.repInfo.id;
            params.qid = that.repInfo.qid;
            params.images = JSON.stringify(that.images);
            if (that.textarea == '') {
                that.utils.err("请输入回复内容");
                return false;
            }
            that.openLoading("回复中....");
            that.newApi.addQuestionReply(params).then(res =>{
                if (res.isSuccess == 1) {
                    that.utils.sus("回复成功");
                    that.textarea = '';
                    that.images = [];
                    that.$emit('change', that.repInfo.index);
                }
            })
        },
    }
}
</script>

<style>
.el-icon-error {
    position: absolute;
    top: 1px;
    right: 1px;
    cursor: pointer;
}
</style>
