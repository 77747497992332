<template>
    <div style="min-height: 990px;overflow: hidden">
        <!--  问答详情  -->
        <div style="overflow: hidden;height: 100%;">
            <contentPage :QuestionInfo="tionInfo" @custom-event="handleCustomEvent" ref="chind"></contentPage>
         </div>
        <!--   回复列表     -->
        <div style="width: 1500px;height: 100%;margin: 0 auto">
            <replyType :data="tionInfo"></replyType>
        </div>
    </div>

</template>
 <script>
import contentPage from "./components/contentPage"
import replyType from "./components/replyType";
 export default {
     components: {
         contentPage,
         replyType,
     },

     computed: {

     },
     data () {
         return {
             tionInfo:{},
             id:""
         }
     },
     mounted:function(){
         this.id = this.$route.query.id;
         this.type =  this.$route.params.status;
         this.getRedEnvelopesQuestionInfo();

         //接受个采纳广播
         this.$EventBus.$on("adoptQuestionReply", (data) => {
           this.$nextTick(() => {
             if (data.code == 1) {
               this.getRedEnvelopesQuestionInfo();
             }
           });
         });

     },
     methods:{
         handleCustomEvent(payload) {
           // 在这里执行父组件的方法
           this.getRedEnvelopesQuestionInfo();
         },

         getRedEnvelopesQuestionInfo:function(){
             var that = this;
             that.newApi.getRedEnvelopesQuestionInfo({
                 id:that.id,
             }).then((res)=>{
                 that.tionInfo = res.data;
             }).catch((err)=>{
                 console.log(err)
             })
         },
     }
 }
 </script>

<style scoped>
.puitre_list {
    background: #FFFFFF;
    overflow: hidden;
    width: 1500px;
    margin-left:200px;
    border-radius: 5px 5px 5px 5px;
    height: auto;
}
.puitre_code {
    background: #FFFFFF;
    overflow: hidden;
    width: 913px;
    margin-left:200px;
    border-radius: 5px 5px 5px 5px;
    height: auto;
}

</style>